<template>
  <v-container class="white px-0" fluid>
    <v-card flat>
      <section-form :model="item" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SectionForm from "./SectionForm";

export default {
  name: "SectionEditor",
  components: { SectionForm },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    ...mapGetters("sections", ["getSectionById"]),

    idParam() {
      return parseInt(this.$route.params.sectionId);
    },
    parentIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  mounted() {
    if (this.editMode) {
      this.item = this.getSectionById(this.idParam);
      if (!this.item) {
        this.loadSectionById(this.idParam).then(() => {
          this.item = this.getSectionById(this.idParam);
        });
      }
    } else {
      setTimeout(() => {
        this.item = {
          storeId: this.parentIdParam,
        };
      }, 300);
    }
  },
  methods: {
    ...mapActions("sections", ["loadSectionById", "saveSection"]),

    save(params) {
      const { item, editMode } = params;
      this.saveSection({ item, editMode }).then(() => {
        this.$router.go(-1);
      });
    },
  },
};
</script>
